import React, { FC, ReactNode } from 'react'

import { colors } from '#styles'

import * as S from './styled'

type Props = {
  header: string
  headerColor?: string
  body: string
  backgroundColor?: string
  button?: ReactNode
  desktopWidth?: string
  alignItems?: string
}

const Card: FC<Props> = ({
  header,
  body,
  backgroundColor = colors.white,
  button,

  headerColor,
  desktopWidth,
  alignItems,
}) => {
  return (
    <S.CardContainer
      key={header}
      backgroundColor={backgroundColor}
      desktopWidth={desktopWidth}
      alignItems={alignItems}
    >
      <S.CardHeader color={headerColor}>{header}</S.CardHeader>
      <S.CardBody>{body}</S.CardBody>
      {button}
    </S.CardContainer>
  )
}

export default Card
