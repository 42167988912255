import styled from 'styled-components'

import { dimensions, typography } from '#styles'

export const CardContainer = styled.div<{
  backgroundColor: string
  desktopWidth?: string
  alignItems?: string
}>`
  background-color: ${props => props.backgroundColor};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: ${props => props.alignItems ?? 'center'};
  padding: 1.5rem 2rem;
  border-radius: 0.5rem;
  gap: 1rem;
  min-height: 18.75rem;
  @media (min-width: ${dimensions.laptop}) {
    width: ${props => props.desktopWidth};
  }
`

export const CardHeader = styled.h2<{ color?: string }>`
  ${typography.displaySm.bold};
  color: ${props => props.color};
`

export const CardBody = styled.p`
  ${typography.bodyMd.regular};
  width: 100%;
`
