import styled from 'styled-components'

import { colors, dimensions, styling } from '#styles'

export const CardsContainer = styled.div<{ marginTop?: string }>`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  gap: ${styling.container.gap.sm};
  padding: 1rem;
  background: ${colors.white};
  border-radius: ${styling.button.borderRadius};

  @media (min-width: ${dimensions.laptop}) {
    /* padding: 2rem; */
    grid-template-columns: repeat(5, 1fr);
    /*  ${styling.container.gap.lg}; */
  }

  @media (min-width: ${dimensions.laptop}) {
    margin-top: ${props => props.marginTop};
    width: ${dimensions.laptop};
    box-shadow: 2px 15px 21px -6px ${colors.graySilver};
  }

  @media (max-width: ${dimensions.laptop}) {
    grid-template-columns: 1fr;
  }
`

export default CardsContainer
