import { useTranslation } from 'next-i18next'
import React, { FC } from 'react'
import { useChat } from 'react-live-chat-loader'

import { colors } from '#styles'

import Button from '../common/Button/Button'
import * as Hero from '../common/Hero'

type Props = {}

const ArticleWButton: FC<Props> = ({}) => {
  const { t } = useTranslation('common')
  const [_, loadChat] = useChat()

  return (
    <Hero.TextContainer hideSm center>
      <Hero.TextSubHeader color={colors.neutral900}>
        {t('faq.subheader')}
      </Hero.TextSubHeader>
      <Hero.TextBody>{t('faq.text')}</Hero.TextBody>
      <Hero.ButtonContainer>
        <Button
          onClick={() => loadChat({ open: true })}
          id="secondary-apply-faq"
        >
          {t('faq.link')}
        </Button>
      </Hero.ButtonContainer>
    </Hero.TextContainer>
  )
}

export default ArticleWButton
