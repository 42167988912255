import Image from 'next/image'
import styled from 'styled-components'

import { colors, dimensions, styling, typography } from '#styles'

export const ComponentContainer = styled.div<{
  backgroundColor: string
  highlighted?: boolean
}>`
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: ${styling.button.borderRadius};
  text-align: center;
  padding: 1rem 0;
  box-shadow: ${props =>
    props.highlighted ? `2px 15px 21px -6px ${colors.graySilver}` : 'none'};
  word-break: break-word;
  hyphens: auto;
  cursor: pointer;

  @media (min-width: ${dimensions.laptop}) {
    max-height: 240px;
  }
`

export const TextBody = styled.article<{
  textBodyColor: string
}>`
  ${typography.bodyMd.semiBold}
  margin-top: ${styling.container.gap.lg};
  color: ${props => props.textBodyColor};
`

export const IconContainer = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
  height: 10rem;
`

export const StyledIcon = styled(Image)`
  width: 64px;
  height: 64px;
`

export const TextHeader = styled.h3<{
  headerColor: string
}>`
  margin-top: 0;
  ${typography.displaySm.bold};
  color: ${props => props.headerColor};

  @media (min-width: ${dimensions.tablet}) {
    ${typography.displayXs.bold};
  }
`
