import type { NextPage } from 'next'
import { GetStaticProps } from 'next'
import Image from 'next/image'
import { Trans, useTranslation } from 'next-i18next'
import React, { useState } from 'react'

import { colors, dimensions } from '#styles'

import { i18n } from '../../../next-i18next.config'
import Chevron from '../../../public/assets/common/chevron.svg'
import ChevronWhite from '../../../public/assets/common/chevron-white.svg'
import Play from '../../../public/assets/common/play.svg'
import AppImage from '../../../public/assets/Franchise/app-image.png'
import BatteryIcon from '../../../public/assets/Franchise/battery-icon.svg'
import GlobePartners from '../../../public/assets/Franchise/brick-partners-globe.png'
import DashboardImage from '../../../public/assets/Franchise/dashboard-image.png'
import DevIcon from '../../../public/assets/Franchise/dev-icon.svg'
import GrowthIcon from '../../../public/assets/Franchise/growth-icon.svg'
import HardwareImage from '../../../public/assets/Franchise/hardware-image.png'
import CanadaPerson from '../../../public/assets/Franchise/market-owner-canada.png'
import ScotlandPerson from '../../../public/assets/Franchise/market-owner-scotland.png'
import LondonPerson from '../../../public/assets/Franchise/network-partner-london.png'
import PartnerImage from '../../../public/assets/Franchise/partner-image.png'
import RedDot from '../../../public/assets/Franchise/partnership-market.png'
import GeoLoc from '../../../public/assets/Franchise/partnership-network.png'
import PaymentsImage from '../../../public/assets/Franchise/payments-image.png'
import PhoneIcon from '../../../public/assets/Franchise/phone-icon.svg'
import RevenueImage from '../../../public/assets/Franchise/revenue-image.png'
import SupportIcon from '../../../public/assets/Franchise/support-icon.svg'
import ThreeBricks from '../../../public/assets/three-bricks-black.png'
import ArticleWButton from '../../components/ArticleWButton/ArticleWButton'
import Button from '../../components/common/Button/Button'
import Card from '../../components/common/Card/Card'
import CardsContainer from '../../components/common/CardsContainer/CardsContainer'
import CardWithImageInMiddle from '../../components/common/CardWithImage/CardWithImageInMiddle'
import GridContainer from '../../components/common/GridContainer/GridContainer'
import * as Hero from '../../components/common/Hero'
import LinkButton, {
  MultiRowLinkButton,
} from '../../components/common/LinkButton/LinkButton'
import NavButton from '../../components/common/NavButton/NavButton'
import { PageWrapper, StyledImage } from '../../components/common/styled'
import VideoModal from '../../components/common/VideoModal/VideoModal'
import Container from '../../components/Container'
import FAQ from '../../components/FAQ/FAQ'
import * as S from '../../components/FAQ/styled'
import HeadComp from '../../components/HeadComp/HeadComp'
import { fetchForceAppless } from '../../lib/api/market'
import { Links, Paths } from '../../lib/constants'
import { createLangHrefs } from '../../lib/createLangHrefs'
import { formatServerSideTranslations, ignoreLocale } from '../../lib/i18n'
import { PageProps } from '../../types/pages'
import { useLinks } from 'src/lib/hooks/useLinks'

const Partnership: NextPage<PageProps> = ({
  langHrefs,
  locale,
  applessOnly,
}) => {
  const { t } = useTranslation('partnership')
  const links = useLinks()

  const faqs = [t('faq.question1'), t('faq.question2'), t('faq.question3')]

  const [videoOpen, setVideoOpen] = useState(false)
  return (
    <PageWrapper>
      <HeadComp
        title={t('head.title')}
        description={t('head.description')}
        langHrefs={langHrefs}
      />
      <Container
        backgroundColor={colors.black}
        className="column-reverse laptop-row"
      >
        <Hero.TextContainer>
          <Hero.TextHeader color={colors.white} size="md">
            {t('launch_manage_scale.header')}
          </Hero.TextHeader>
          <Hero.TextBody color={colors.white}>
            {t('launch_manage_scale.body')}
          </Hero.TextBody>
          <Hero.ButtonContainer>
            <NavButton
              href={links.partnership.index.section_1.link}
              id="primary-apply-banner"
            >
              {t('launch_manage_scale.link_apply')}
            </NavButton>
            <Button
              id={'secondary-play_video-banner'}
              transparent
              textColor={colors.greenAccent}
              onClick={() => setVideoOpen(true)}
            >
              <Image
                alt={t('launch_manage_scale.icon_alt')}
                src={Play}
                priority
              />
              {t('launch_manage_scale.link_about')}
            </Button>
          </Hero.ButtonContainer>
        </Hero.TextContainer>
        <Hero.ImageWrapper>
          <StyledImage
            src={ThreeBricks}
            priority
            alt={t('launch_manage_scale.image_alt')}
            placeholder="blur"
            sizes={`(min-width: ${dimensions.laptop}) 40vw, 100vw`}
          />
        </Hero.ImageWrapper>
        <VideoModal
          open={videoOpen}
          handleClose={() => setVideoOpen(false)}
          videoUrl="../assets/Videos/brick-commercial-full-day"
        />
      </Container>

      <Container className="column laptop-row">
        <Hero.ImageWrapper>
          <Image
            style={{ width: '100%', height: 'auto' }}
            placeholder="blur"
            sizes={`(min-width: ${dimensions.laptop}) 40vw, 80vw`}
            src={GlobePartners}
            priority
            alt={t('bring_brick_market.image_alt')}
          />
        </Hero.ImageWrapper>
        <Hero.TextContainer>
          <Hero.TextHeader>{t('bring_brick_market.header')}</Hero.TextHeader>
          <Hero.TextBody>{t('bring_brick_market.body')}</Hero.TextBody>
          <NavButton
            id="secondary-apply-bring_brick"
            href={links.partnership.index.section_2.link}
            transparent
            textColor={colors.redTorch}
          >
            {t('bring_brick_market.link')}
            <Image
              alt={t('bring_brick_market.chevron_alt')}
              src={Chevron}
              priority
            />
          </NavButton>
        </Hero.TextContainer>
      </Container>
      {/* FOLD */}
      <Container className="column">
        <Hero.SectionContainer>
          <Hero.TextHeader>
            {t('get_started_brick_partner.header')}
          </Hero.TextHeader>
          <Hero.SecondaryTextBody>
            {t('get_started_brick_partner.body')}
          </Hero.SecondaryTextBody>
        </Hero.SectionContainer>
        <Hero.SectionWrapper gap>
          <Hero.TextContainer>
            <Hero.ImageWrapper $background={colors.blackAccent} padding full>
              <Image
                style={{ width: '100%', height: 'auto' }}
                placeholder="blur"
                sizes={`(min-width: ${dimensions.laptop}) 40vw, 100vw`}
                src={RedDot}
                alt={t('get_started_brick_partner_market.image_alt')}
              />
            </Hero.ImageWrapper>
            <Hero.TextSubHeader>
              {t('get_started_brick_partner_market.subheader')}
            </Hero.TextSubHeader>
            <Hero.SecondaryTextBody>
              {t('get_started_brick_partner_market.body')}
            </Hero.SecondaryTextBody>
            <NavButton
              id="primary-apply-book_mo"
              href={links.partnership.index.section_3.card_1.link}
              textColor={colors.white}
            >
              {t('get_started_brick_partner.link')}
            </NavButton>
          </Hero.TextContainer>
          <Hero.TextContainer marginTop>
            <Hero.ImageWrapper
              $background={colors.blackAccent}
              padding
              full
              marginTop
            >
              <Image
                style={{ width: '100%', height: 'auto' }}
                placeholder="blur"
                sizes={`(min-width: ${dimensions.laptop}) 40vw, 100vw`}
                src={GeoLoc}
                alt={t('get_started_brick_partner_network.image_alt')}
              />
            </Hero.ImageWrapper>
            <Hero.TextSubHeader>
              {t('get_started_brick_partner_network.subheader')}
            </Hero.TextSubHeader>
            <Hero.SecondaryTextBody>
              {t('get_started_brick_partner_network.body')}
            </Hero.SecondaryTextBody>
            <NavButton
              id="primary-apply-book_np"
              href={links.partnership.index.section_3.card_2.link}
              textColor={colors.white}
            >
              {t('get_started_brick_partner.link')}
            </NavButton>
          </Hero.TextContainer>
        </Hero.SectionWrapper>
      </Container>

      <Container backgroundColor={colors.blackAccent} className="column">
        <Hero.SectionContainer>
          <Hero.TextHeader color={colors.white}>
            {t('get_to_know.header')}
          </Hero.TextHeader>
          <Hero.TextBody color={colors.white}>
            {t('get_to_know.body')}
          </Hero.TextBody>
        </Hero.SectionContainer>
        <Hero.SectionWrapper gap>
          <Hero.TextContainer>
            <Hero.SecondaryImageWrapper marginTop full>
              <Image
                style={{ width: '100%', height: 'auto' }}
                placeholder="blur"
                sizes={`(min-width: ${dimensions.laptop}) 40vw, 100vw`}
                src={ScotlandPerson}
                alt={t('get_to_know_scotland.image_alt')}
              />
            </Hero.SecondaryImageWrapper>
            <Hero.TextSubHeader color={colors.redTorch}>
              <Trans
                t={t}
                ns={'partnership'}
                i18nKey="get_to_know_scotland.subheader"
                components={{ white: <span style={{ color: colors.white }} /> }}
              />
            </Hero.TextSubHeader>
            <Hero.TextBody color={colors.white}>
              {t('get_to_know_scotland.body')}
            </Hero.TextBody>
          </Hero.TextContainer>
          <Hero.TextContainer>
            <Hero.SecondaryImageWrapper marginTop full>
              <Image
                style={{ width: '100%', height: 'auto' }}
                placeholder="blur"
                sizes={`(min-width: ${dimensions.laptop}) 40vw, 100vw`}
                src={LondonPerson}
                alt={t('get_to_know_london.image_alt')}
              />
            </Hero.SecondaryImageWrapper>
            <Hero.TextSubHeader color={colors.redTorch}>
              <Trans
                t={t}
                ns={'partnership'}
                i18nKey="get_to_know_london.subheader"
                components={{ white: <span style={{ color: colors.white }} /> }}
              />
            </Hero.TextSubHeader>
            <Hero.TextBody color={colors.white}>
              {t('get_to_know_london.body')}
            </Hero.TextBody>
          </Hero.TextContainer>
          <Hero.TextContainer>
            <Hero.SecondaryImageWrapper marginTop full>
              <Image
                style={{ width: '100%', height: 'auto' }}
                placeholder="blur"
                sizes={`(min-width: ${dimensions.laptop}) 40vw, 100vw`}
                src={CanadaPerson}
                alt={t('get_to_know_canada.image_alt')}
              />
            </Hero.SecondaryImageWrapper>
            <Hero.TextSubHeader color={colors.redTorch}>
              <Trans
                t={t}
                ns={'partnership'}
                i18nKey="get_to_know_canada.subheader"
                components={{ white: <span style={{ color: colors.white }} /> }}
              />
            </Hero.TextSubHeader>
            <Hero.TextBody color={colors.white}>
              {t('get_to_know_canada.body')}
            </Hero.TextBody>
          </Hero.TextContainer>
        </Hero.SectionWrapper>
      </Container>

      <Hero.OverTopContainer>
        <Hero.OverTopSection>
          <Hero.TextContainer
            style={{
              alignItems: 'center',
              maxWidth: '100%',
              textAlign: 'center',
              marginTop: '0',
            }}
          >
            <Hero.TextBody color={colors.white} hideTextOnLargeScreen>
              {t('we_are_growing.body')}
            </Hero.TextBody>
          </Hero.TextContainer>
        </Hero.OverTopSection>
      </Hero.OverTopContainer>
      <Container className="column" backgroundColor={colors.white}>
        <CardsContainer className="column laptop-row" marginTop="-15rem">
          <CardWithImageInMiddle
            image={{
              src: BatteryIcon,
              alt: t('we_are_growing.image_alt'),
            }}
            {...t('we_are_growing_hardware')}
          />
          <CardWithImageInMiddle
            image={{
              src: DevIcon,
              alt: t('we_are_growing.image_alt'),
            }}
            {...t('we_are_growing_software')}
          />
          <CardWithImageInMiddle
            highlighted
            image={{
              src: GrowthIcon,
              alt: t('we_are_growing.image_alt'),
            }}
            {...t('we_are_growing_business')}
          />

          <CardWithImageInMiddle
            image={{
              src: PhoneIcon,
              alt: t('we_are_growing.image_alt'),
            }}
            {...t('we_are_growing_customer')}
          />

          <CardWithImageInMiddle
            image={{
              src: SupportIcon,
              alt: t('we_are_growing.image_alt'),
            }}
            {...t('we_are_growing_tech')}
          />
        </CardsContainer>
        <Hero.TextContainer
          style={{
            alignItems: 'center',
            maxWidth: '100%',
            textAlign: 'center',
          }}
        >
          <Hero.TextBody color={colors.black} showTextOnLargeScreen>
            {t('we_are_growing.body')}
          </Hero.TextBody>
        </Hero.TextContainer>
      </Container>

      <Container className="column-reverse tablet-column laptop-row-reverse">
        <Hero.SecondaryImageWrapper xSmall>
          <Image
            style={{ width: '100%', height: 'auto' }}
            placeholder="blur"
            sizes={`(min-width: ${dimensions.laptop}) 40vw, 100vw`}
            src={HardwareImage}
            alt={t('hardware.image_alt')}
          />
        </Hero.SecondaryImageWrapper>
        <Hero.ColoredTextContainer backgroundColor={colors.redTorch}>
          <Hero.TextHeader color={colors.white}>
            {t('hardware.header')}
          </Hero.TextHeader>
          <Hero.TextBody color={colors.white}>
            {t('hardware.body')}
          </Hero.TextBody>
          <NavButton
            id="secondary-apply-book_hardware"
            href={links.partnership.index.section_6.link}
            transparent
            textColor={colors.white}
          >
            {t('hardware.link')}
            <Image alt={t('hardware.chevron_alt')} src={ChevronWhite} />
          </NavButton>
        </Hero.ColoredTextContainer>
      </Container>

      <Container className="column laptop-row">
        <Hero.SecondaryImageWrapper>
          <Image
            style={{ width: '100%', height: 'auto' }}
            placeholder="blur"
            sizes={`(min-width: ${dimensions.laptop}) 40vw, 100vw`}
            src={DashboardImage}
            alt={t('dashboard.image_alt')}
          />
        </Hero.SecondaryImageWrapper>
        <Hero.TextContainer>
          <Hero.TextHeader>{t('dashboard.header')}</Hero.TextHeader>
          <Hero.TextBody>{t('dashboard.body')}</Hero.TextBody>
          <NavButton
            id="secondary-apply-book_dashboard"
            href={links.partnership.index.section_7.link}
            transparent
            textColor={colors.redTorch}
          >
            {t('dashboard.link')}
            <Image alt={t('dashboard.chevron_alt')} src={Chevron} />
          </NavButton>
        </Hero.TextContainer>
      </Container>

      <Container className="column-reverse tablet-column laptop-row-reverse">
        <Hero.SecondaryImageWrapper xSmall>
          <Image
            style={{ width: '100%', height: 'auto' }}
            placeholder="blur"
            sizes={`(min-width: ${dimensions.laptop}) 40vw, 100vw`}
            src={AppImage}
            alt={t('app.image_alt')}
          />
        </Hero.SecondaryImageWrapper>
        <Hero.ColoredTextContainer backgroundColor={colors.greenAccent}>
          <Hero.TextHeader color={colors.white}>
            {t('app.header')}
          </Hero.TextHeader>
          <Hero.TextBody color={colors.white}>{t('app.body')}</Hero.TextBody>
          <LinkButton
            id="secondary-link_internal-brick_app"
            href={links.partnership.index.section_8.link}
            transparent
            textColor={colors.white}
          >
            {t('app.link')}
            <Image alt={t('app.chevron_alt')} src={ChevronWhite} />
          </LinkButton>
        </Hero.ColoredTextContainer>
      </Container>

      <Container className="column laptop-row">
        <Hero.ImageWrapper>
          <Image
            style={{ width: '100%', height: 'auto' }}
            placeholder="blur"
            sizes={`(min-width: ${dimensions.laptop}) 40vw, 100vw`}
            src={PaymentsImage}
            alt={t('payments.image_alt')}
          />
        </Hero.ImageWrapper>
        <Hero.TextContainer>
          <Hero.TextHeader>{t('payments.header')}</Hero.TextHeader>
          <Hero.TextBody>{t('payments.body')}</Hero.TextBody>
          <LinkButton
            id="secondary-link_external-payments"
            href={links.partnership.index.section_9.link}
            transparent
            textColor={colors.redTorch}
          >
            {t('payments.link')}
            <Image alt={t('payments.chevron_alt')} src={Chevron} />
          </LinkButton>
        </Hero.TextContainer>
      </Container>

      <Container className="column-reverse tablet-column laptop-row-reverse">
        <Hero.SecondaryImageWrapper xSmall>
          <Image
            style={{ width: '100%', height: 'auto' }}
            placeholder="blur"
            sizes={`(min-width: ${dimensions.laptop}) 40vw, 100vw`}
            src={RevenueImage}
            alt={t('revenue.image_alt')}
          />
        </Hero.SecondaryImageWrapper>
        <Hero.ColoredTextContainer backgroundColor={colors.blueAccent}>
          <Hero.TextHeader color={colors.white}>
            {t('revenue.header')}
          </Hero.TextHeader>
          <Hero.TextBody color={colors.white}>
            {t('revenue.body')}
          </Hero.TextBody>
          <MultiRowLinkButton
            id="secondary-link_external-revenue_split"
            href={links.partnership.index.section_10.link}
            transparent
            textColor={colors.white}
          >
            {t('revenue.link')}
            <Image alt={t('revenue.chevron_alt')} src={ChevronWhite} />
          </MultiRowLinkButton>
        </Hero.ColoredTextContainer>
      </Container>

      <Container className="column laptop-row">
        <Hero.ImageWrapper>
          <Image
            style={{ width: '100%', height: 'auto' }}
            placeholder="blur"
            sizes={`(min-width: ${dimensions.laptop}) 40vw, 100vw`}
            src={PartnerImage}
            alt={t('partner.image_alt')}
          />
        </Hero.ImageWrapper>
        <Hero.TextContainer>
          <Hero.TextHeader>{t('partner.header')}</Hero.TextHeader>
          <Hero.TextBody>{t('partner.body')}</Hero.TextBody>
          <LinkButton
            id="secondary-link_external-partner_sucess"
            href={links.partnership.index.section_11.link}
            transparent
            textColor={colors.redTorch}
          >
            {t('partner.link')}
            <Image alt={t('partner.chevron_alt')} src={Chevron} />
          </LinkButton>
        </Hero.TextContainer>
      </Container>

      <Container
        className="column"
        backgroundColor={colors.neutral900}
        style={{ gap: '2rem' }}
      >
        <Hero.TextContainer
          style={{
            alignItems: 'center',
            maxWidth: '100%',
            textAlign: 'center',
          }}
        >
          <Hero.TextHeader color={colors.white}>
            {t('learn_more.header')}
          </Hero.TextHeader>
        </Hero.TextContainer>
        <GridContainer>
          <Card
            alignItems="flex-start"
            {...t('learn_more1')}
            button={
              <LinkButton
                id="secondary-link_external-5_steps"
                href={Links.LEARN_MORE.FIVE_STEPS}
                transparent
                textColor={colors.redTorch}
              >
                {t('learn_more.link')}
                <Image alt={t('learn_more.chevron_alt')} src={Chevron} />
              </LinkButton>
            }
          />
          <Card
            alignItems="flex-start"
            {...t('learn_more2')}
            button={
              <LinkButton
                id="secondary-link_external-cost_start"
                href={Links.LEARN_MORE.STARTING_COST}
                transparent
                textColor={colors.redTorch}
              >
                {t('learn_more.link')}
                <Image alt={t('learn_more.chevron_alt')} src={Chevron} />
              </LinkButton>
            }
          />
          <Card
            alignItems="flex-start"
            {...t('learn_more3')}
            button={
              <LinkButton
                id="secondary-link_external-cost_power"
                href={Links.LEARN_MORE.POWER_CONSUMPTION}
                transparent
                textColor={colors.redTorch}
              >
                {t('learn_more.link')}
                <Image alt={t('learn_more.chevron_alt')} src={Chevron} />
              </LinkButton>
            }
          />

          <Card
            alignItems="flex-start"
            {...t('learn_more4')}
            button={
              <LinkButton
                id="secondary-link_external-expect_earn"
                href={Links.LEARN_MORE.EARNING_EXPECTATION}
                transparent
                textColor={colors.redTorch}
              >
                {t('learn_more.link')}
                <Image alt={t('learn_more.chevron_alt')} src={Chevron} />
              </LinkButton>
            }
          />

          <Card
            alignItems="flex-start"
            {...t('learn_more5')}
            button={
              <LinkButton
                id="secondary-link_external-help_sign"
                href={Links.LEARN_MORE.SIGNING_HELP}
                transparent
                textColor={colors.redTorch}
              >
                {t('learn_more.link')}
                <Image alt={t('learn_more.chevron_alt')} src={Chevron} />
              </LinkButton>
            }
          />
          <Card
            alignItems="flex-start"
            {...t('learn_more6')}
            button={
              <LinkButton
                id="secondary-link_external-promote_business"
                href={Links.LEARN_MORE.PROMOTE_BUSINESS}
                transparent
                textColor={colors.redTorch}
              >
                {t('learn_more.link')}
                <Image alt={t('learn_more.chevron_alt')} src={Chevron} />
              </LinkButton>
            }
          />
        </GridContainer>
        <LinkButton
          id="secondary-link_external-all_articles"
          href={links.partnership.index.section_12.link}
          textColor={colors.redTorch}
          buttonColor={colors.white}
        >
          {t('learn_more.link_all')}
          <Image alt={t('learn_more.chevron_alt_all')} src={Chevron} />
        </LinkButton>
      </Container>
      <Container className="row">
        <FAQ applessOnly={applessOnly} faqs={faqs} locale={locale}>
          {<S.Header>Frequently Asked Questions</S.Header>}
        </FAQ>
        <ArticleWButton />
      </Container>
    </PageWrapper>
  )
}

export default Partnership

export const getStaticProps: GetStaticProps<PageProps> = async ({
  locale = i18n.defaultLocale,
  locales,
}) => ({
  props: {
    ...(await formatServerSideTranslations(locale, [
      'common',
      'navigation',
      'partnership',
    ])),
    langHrefs: createLangHrefs(Paths.PARTNERSHIP, locale, locales),
    locale,
    applessOnly: await fetchForceAppless(locale),
    button: 'APPLY_PARTNER',
  },
  notFound: ignoreLocale(locale, ['default']),
  revalidate: 60 * 60,
})
