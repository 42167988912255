import { ImageProps } from 'next/image'
import React, { FC, ReactElement } from 'react'

import { colors } from '#styles'

import * as S from './styled'

type Props = {
  image: ImageProps
  isPriorityImage?: boolean
  header?: string
  headerColor?: string
  body?: string | ReactElement
  textBodyColor?: string
  backgroundColor?: string
  highlighted?: boolean
}

const CardWithImage: FC<Props> = ({
  image,
  isPriorityImage = false,
  header,
  headerColor = colors.redTorch,
  body,
  textBodyColor = colors.redTorch,
  backgroundColor = colors.white,
  highlighted,
}) => {
  return (
    <S.ComponentContainer
      backgroundColor={backgroundColor}
      highlighted={highlighted}
    >
      <S.TextBody textBodyColor={textBodyColor}>{body}</S.TextBody>
      <S.IconContainer>
        <S.StyledIcon
          src={image.src}
          alt={image.alt}
          priority={isPriorityImage}
        />
      </S.IconContainer>
      <S.TextHeader headerColor={headerColor}>{header}</S.TextHeader>
    </S.ComponentContainer>
  )
}

export default CardWithImage
