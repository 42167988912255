import styled from 'styled-components'

import { dimensions, styling } from '#styles'

export const GridContainer = styled.div<{}>`
  display: grid;
  gap: ${styling.container.gap.md};

  @media (min-width: ${dimensions.tablet}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: ${dimensions.laptop}) {
    grid-template-columns: repeat(3, 1fr);
  }
`

export default GridContainer
